<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="packs">
      <div class="row padding" id="exercices" v-if="itemId === 'exercices' || itemId === 'exercises' || itemId === 'ejercicios'">
        <ProductShop :programme="true" :change="locale[lang].CHANGE_G" :info="locale[lang].PACKS.INFOEXERCICES" />
      </div>

      <div class="row padding" id="jeunes" v-if="itemId === 'jeunes' || itemId === 'youth' || itemId === 'jovenes'">
        <ProductShop :programme="false" :info="locale[lang].PACKS.INFOJEUNES" />
      </div>

      <div class="row padding" id="senior" v-if="itemId === 'senior' || itemId === 'adult'">
        <ProductShop :programme="false" :info="locale[lang].PACKS.INFOSENIOR" />
      </div>

      <div class="row padding" id="formation" v-if="itemId === 'formation' || itemId === 'e-learning' || itemId === 'formación'">
        <ProductShop :programme="false" :info="locale[lang].PACKS.INFOFORMATION" />
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import ProductShop from "@/components/ProductShop.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";

export default {
  components: {
    ProductShop,
    NavBar,
    Footer,
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
  },
  created() {},
};
</script>

<style scoped>
.paypal {
  padding: 7% 5%;
}

h1 {
  font: normal normal 800 38px/47px Montserrat;
  color: #0f1010;
}

.title {
  position: relative;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 61px;
  left: 48%;
  bottom: -25%;
  opacity: 1;
}

.fa-plus,
.fa-arrow-right {
  height: 40px;
  width: 40px;
  margin: 0 40px;
  color: #ff0313;
}

h3 {
  font: normal normal 600 22px/34px Montserrat;
  color: #0f1010;
}

.padding {
  padding: 3% 12%;
}

.pack {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #93939340;
  border-radius: 12px;
  border: 1px solid #0f1010;
  padding: 14px 20px;
}

#packs {
  margin-top: 40px;
  background-image: url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Background/Trazado 2786.png"),
    url("../assets/Images/Background/Elipse 102.png"),
    url("../assets/Images/Background/Grupo 1027.png");
  background-repeat: no-repeat;
  background-size: auto, 276px 242px, auto;
  background-position: 50% 82%, 100% 100%, 88% 15%, 5% 5%;
}

@media (max-width: 1440px) {
  .padding {
    padding: 3% 10%;
  }
}

@media (max-width: 825px) {
  .padding {
    padding: 3% 5%;
  }
}
</style>
